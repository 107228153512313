import React, { FC,useState ,useEffect} from 'react';
import './styles.css';

import InterestedCourseBG from 'src/assets/img/interested-in-course-bg1.png';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';
import NHGEnquiryForm from 'src/components/NHGEnquiryForm/GlobalPopupEnquiry';
const Breadcrumbs: FC<{ schooldetail: any }> = ({ schooldetail }) => {

    const [gurukul, setGurukul] = useState(false);

    const linksToCheck = [
        'new-horizon-gurukul-school-bangalore',
        'new-horizon-vidya-mandir-nhvm-bangalore',
        'new-horizon-public-school-nhps-bangalore',
        'new-horizon-international-school-nhis-bangalore',
       
      ];


      const getcheck = () => {
        try {
            const currentURL = window.location.href;
            const isGurukul = linksToCheck.some(link => currentURL.includes(link));
            // console.log('Current URL:', currentURL);
            // console.log('Is Gurukul:', isGurukul);
            setGurukul(isGurukul);
          } catch (error) {
            console.error('Error in URL checking:', error);
          }
          
      };

      useEffect(() => {
        getcheck();

    }, []);
    
    return (
        <section className="container-fluid spac_01 "  >
            <div className="container">
                <div className="row">
                    <div className="row interested-in-course text-center"  style={{ background: `#fff url(${InterestedCourseBG})` }}>
                        <div className="course-center">   
                            <p>Interested in this School? Get Admission now!</p>
                            <h2>{ schooldetail.school_name }</h2>
                            <div className="text-center">
                            {gurukul ? <NHGEnquiryForm className="btn btn btn-primary" title="Request Call Back" />:<GlobalPopupEnquiry  className="btn btn btn-primary"  title="Request Call Back" />}
                                
                                </div>
                        </div>
                    </div>
                </div>     
            </div>
        </section>
    );
};

export default Breadcrumbs;